import * as z from "zod"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const ProfileBuilderDbModel = z.object({
  id: z.string(),
  name: z.string(),
  deleted: z.boolean().nullish(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  workspaceId: z.string(),
  intermediateStorageCredentials: jsonSchema,
  destinationId: z.string().nullish(),
  connectionOptions: jsonSchema,
  version: z.number().int(),
})
