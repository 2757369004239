import * as z from "zod"

export const ProfileBuilderStateDbModel = z.object({
  profileBuilderId: z.string(),
  profileBuilderVersion: z.number().int(),
  instanceIndex: z.number().int(),
  totalInstances: z.number().int(),
  startedAt: z.date(),
  updatedAt: z.date(),
  lastTimestamp: z.date().nullish(),
  processedUsers: z.number().int(),
  totalUsers: z.number().int(),
  errorUsers: z.number().int(),
  speed: z.number(),
})
